import { EditVersionType, LocationType } from '@/common/enums';
import { IAssetEditVersion, IAssetEstimateRecord, IMutateCrew, IMutateParticipant } from '@/common/types/asset';
import { ICompanyAddress, ICompanyRecord } from '@/common/types/company';
import { FilterModel, FilterOption, IFilterModel, IPagedResultModel } from '@/common/types/filterModel';
import { IAssetEstimateForm } from '@/modules/asset/components/tabs/estimation/assetEstimateForm';
import { AxiosResponse } from 'axios';
import { httpClient } from './httpClient';
import { IAddCastToAsset, IAddSession, IAssetAddEditVersionCast, IAssetCrewRecord, IAssetParticipantRecord, IAssetRecord, IAssetRightTracker, IAssetRightTrackerFilter, IAssetSignatoryMutate, IAssetTransferOfRightMutate, IAssetTransferOfRightRecord, ICastIntegratedSource, ICastRecord, ICastStackPositionUpdateModel, ICreateAsset, IDeleteAssetSignatory, IDeleteCrew, IDeleteParticipant, IEditVersionBasicDetail, IEditVersionBasicDetailPayload, IInvoiceMessageModel, ILocation, ILocationMutate, IOperationResultModel, ISessionRecord, IUnionAgreement, IUpdateAssetNoteModel, IUpdateCastToAsset } from './models/asset';
import { IDeleteDocumentValue, IDocumentValue, IDocumentViewModel, ISignatoryVendorRecord, StaffViewModel } from './models/company';
import { IGuaranteeContractDetails } from './models/contract';
import { IAgencyInfo, IPersonRecord } from './models/person';
import { ISignatoryRecord } from '@/modules/company/shared/models/company';
import { IContractRecord } from '@/common/types/contract';

export const createAsset = (payload: ICreateAsset) => {
	return httpClient.post('/Asset', payload);
};

export const addCastToAsset = (payload: IAddCastToAsset) => {
	return httpClient.post('/Asset/Cast/Add', payload);
};

export const addSessionToAsset = (payload: IAddSession) => {
	return httpClient.post<IOperationResultModel | IInvoiceMessageModel>('/Asset/Session', payload);
};

export const addAssetEditVersion = (originalAssetId: string, payload: IAssetEditVersion) => {
	return httpClient.post<IOperationResultModel>(`/Asset/EditVersion/${originalAssetId}`, payload);
};

export const addAssetLocation = (assetId: string, payload: ILocationMutate) => {
	return httpClient.post<IOperationResultModel>(`/Asset/Location/Save/${assetId}`, payload);
};

export const addAssetCrew = (payload: IMutateCrew) => {
	return httpClient.post('/Asset/AddCrew', payload);
};

export const addAssetParticipant = (payload: IMutateParticipant) => {
	return httpClient.post('/Asset/Participant/Add', payload);
};

export const addAssetEstimate = (assetId: string, payload: IAssetEstimateForm) => {
	return httpClient.post<IOperationResultModel>(`/Asset/Estimate/Save/${assetId}`, payload);
};

export const addAssetSignatory = (assetId: string, payload: IAssetSignatoryMutate) => {
	return httpClient.post<IOperationResultModel>(`/Asset/Signatory/Save/${assetId}`, payload);
};

export const addAssetTransferOfRight = (assetId: string, payload: IAssetTransferOfRightMutate) => {
	return httpClient.post<IOperationResultModel>(`/Asset/TransferOfRight/Save/${assetId}`, payload);
};

export const getAllAsset = (filters: IFilterModel, includeEditVersion: boolean = false) => {
	return httpClient.post<IPagedResultModel<IAssetRecord>>('/Asset/GetBy', filters, { includeEditVersion });
};

export const getAllAssetForBasicDetail = (filters: IFilterModel, includeEditVersion: boolean = false) => {
	return httpClient.post<IPagedResultModel<IAssetRecord>>('/Asset/GetAllForBasicDetail', filters, { includeEditVersion });
};

export const getIntegratedSourceForCast = (filters: IFilterModel, personId: string) => {
	return httpClient.post<IPagedResultModel<ICastIntegratedSource>>('/Asset/Cast/GetIntegratedSource', filters, {personId});
};

export const getAssetById = (assetId: string) => {
	return httpClient.post<IAssetRecord>(`/Asset/GetById/${assetId}`);
};

export const submitCompletionReportThroughAsset = (assetId: string) => {
	return httpClient.post(`/Asset/CompletionReport/Submit/${assetId}`, {});
};

export const getDefaultPayrollVendor = (assetId: string) => {
	return httpClient.post<ICompanyRecord>(`/Asset/GetDefaultPayrollVendor/${assetId}`);
};

export const getAllSession = (assetId: string, filters: IFilterModel) => {
	return httpClient.post<IPagedResultModel<ISessionRecord>>(`/Asset/GetAllSession/${assetId}`, filters);
};

export const getAllCast = (assetId: string, isEditVersion: boolean, filters: IFilterModel, excludeElementCast: boolean = false) => {
	return httpClient.post<IPagedResultModel<ICastRecord>>(`/Asset/Cast/GetAll/${assetId}/${isEditVersion}`, filters, { excludeElementCast });
};

export const getAllCrew = (assetId: string, filters: IFilterModel) => {
	return httpClient.post<IPagedResultModel<IAssetCrewRecord>>(`/Asset/GetAllCrew/${assetId}`, filters);
};

export const getAllCrewIds = (assetId: string) => {
	return httpClient.get<string[]>(`/Asset/GetAllCrewIds/${assetId}`);
};

export const getExclusiveCategories = (assetId: string) => {
	return httpClient.get<string[]>(`/Asset/Product/GetExclusiveCategories/${assetId}`);
};

export const getAllParticipant = (assetId: string, filters: IFilterModel) => {
	return httpClient.post<IPagedResultModel<IAssetParticipantRecord>>(`/Asset/Participant/GetAll/${assetId}`, filters);
};

export const getAllLinkedParticipant = (assetId: string, filters: IFilterModel) => {
	return httpClient.post<IPagedResultModel<IAssetParticipantRecord>>(`/Asset/Participant/GetAllLinked/${assetId}`, filters);
};

export const getManualAndLinkedParticipant = async (
	assetId: string,
	filterModel: FilterModel
): Promise<AxiosResponse<IPagedResultModel<IAssetParticipantRecord>, any>> => {
	const [addedParticipant, linkedParticipant] = await Promise.all([
		getAllParticipant(assetId, filterModel),
		getAllLinkedParticipant(assetId, filterModel),
	]);

	const totalRecords =
		(addedParticipant?.data?.totalRecords ?? 0) +
		(linkedParticipant?.data?.totalRecords ?? 0);
	const records = [
		...(addedParticipant?.data?.records ?? []),
		...(linkedParticipant?.data?.records ?? []),
	];

	return {
		...addedParticipant,
		...linkedParticipant,
		data: { totalRecords, records },
	};
};

export const uploadInvoice = (payload: any) => {
	return httpClient.post('/Asset/Invoice/Upload', payload);
};

export const getCombinedSignatory = async (
	assetId: string,
	filterModel: FilterModel
): Promise<AxiosResponse<IPagedResultModel<ISignatoryVendorRecord>, any>> => {
	const [addedSignatory, linkedSignatory] = await Promise.all([
		getAllSignatory(assetId, filterModel),
		getAllLinkedSignatory(assetId, filterModel),
	]);

	const totalRecords =
		(addedSignatory?.data?.totalRecords ?? 0) +
		(linkedSignatory?.data?.totalRecords ?? 0);
	const records = [
		...(addedSignatory?.data?.records ?? []),
		...(linkedSignatory?.data?.records ?? []),
	];

	return {
		...addedSignatory,
		...linkedSignatory,
		data: { totalRecords, records },
	};
};

export const getCombinedSignatoryPrimaryStaff = async(
	filterModel: FilterModel,
	companyId: string,
): Promise<AxiosResponse<IPagedResultModel<StaffViewModel>, any>> => {

	if(!filterModel?.filters){
		filterModel.filters = [];
	}
	const pFilters = JSON.parse(JSON.stringify(filterModel));
	const nFilters = JSON.parse(JSON.stringify(filterModel));

	pFilters.filters = [...pFilters.filters, new FilterOption('true', 'StaffsDetails.HasStaff.IsPrimaryStaff')];
	nFilters.filters = [...nFilters.filters, new FilterOption('false', 'StaffsDetails.HasStaff.IsPrimaryStaff')];

	const [pSignatory, nSignatory] = await Promise.all([
		getStaffListByCompanyForAsset(pFilters, companyId),
		getStaffListByCompanyForAsset(nFilters, companyId),
	]);

	const totalRecords =
		(pSignatory?.data?.totalRecords ?? 0) +
		(nSignatory?.data?.totalRecords ?? 0);
	const records = [
		...(pSignatory?.data?.records ?? []),
		...(nSignatory?.data?.records ?? []),
	];

	return {
		...pSignatory,
		...nSignatory,
		data: { totalRecords, records },
	};
};

export const getAllSignatory = (assetId: string, filters: IFilterModel) => {
	return httpClient.post<IPagedResultModel<ISignatoryVendorRecord>>(`/Asset/Signatory/GetAll/${assetId}`, filters);
};

export const getAllLinkedSignatory = (assetId: string, filters: IFilterModel) => {
	return httpClient.post<IPagedResultModel<ISignatoryVendorRecord>>(`/Asset/Signatory/GetAllLinked/${assetId}`, filters);
};

export const getAssetRightTrackerDetail = (assetId: string, filter: IAssetRightTrackerFilter) => {
	return httpClient.post<IAssetRightTracker>(`/Asset/GetRightsTrackerDetail/${assetId}`, filter);
};

export const getRightsTrackerDetailForAssetProfile = (assetId: string, filter: IAssetRightTrackerFilter) => {
	return httpClient.post<IAssetRightTracker>(`/Asset/GetRightsTrackerDetailForAssetProfile/${assetId}`, filter);
};

export const getAllEditVersion = (originalAssetId: string, filters: IFilterModel) => {
	return httpClient.post<IPagedResultModel<IAssetRecord>>(`/Asset/GetAllEditVersion/${originalAssetId}`, filters);
};

export const getNextEditVersion = (originalAssetId: string, editVersionType: EditVersionType = EditVersionType.Edit) => {
	return httpClient.get<string>(`/Asset/GetNextEditVersion/${editVersionType}/${originalAssetId}`);
};

export const getNextLocation = (assetId: string, locationType: LocationType = LocationType.Filming) => {
	return httpClient.get<string>(`/Asset/GetNextLocationNumber/${locationType}/${assetId}`);
};

export const getAllEditVersionBasicDetail = (payload: IEditVersionBasicDetailPayload) => {
	return httpClient.post<IEditVersionBasicDetail>('/Asset/GetEditVersionCastDetail', payload);
};

export const getAllAssetLocation = (assetId: string, filters: IFilterModel) => {
	return httpClient.post<IPagedResultModel<ILocation>>(`/Asset/Location/GetBy/${assetId}`, filters);
};

export const getLocationById = (locationId: string) => {
	return httpClient.get<ILocation>(`/Asset/Location/GetById/${locationId}`);
};

export const getLastWorkDateOfLocationByIds = (locationIds: string[]) => {
	return httpClient.post<Date | undefined>('/Asset/Location/GetLastWorkDate', locationIds);
};

export const getAllAssetEstimate = (assetId: string, filters: IFilterModel) => {
	return httpClient.post<IPagedResultModel<IAssetEstimateRecord>>(`/Asset/Estimate/GetBy/${assetId}`, filters);
};

export const getAllTransferOfRight = (filters: IFilterModel) => {
	return httpClient.post<IPagedResultModel<IAssetTransferOfRightRecord>>('/Asset/TransferOfRight/GetBy', filters);
};

export const getAllAssetSignatoryVendorIds = (assetId: string) => {
	return httpClient.get<string[]>(`Asset/SignatoryVendor/GetAllIds/${assetId}`);
};

export const updateCastDetail = (payload: IUpdateCastToAsset) => {
	return httpClient.post<IOperationResultModel>('/Asset/Cast/Update', payload);
};

export const updateCastListStackPosition = (payload: ICastStackPositionUpdateModel) => {
	return httpClient.post('/Asset/Cast/StackPosition', payload);
};

export const editAssetById = (assetId: string, payload: ICreateAsset) => {
	return httpClient.put<IOperationResultModel>(`/Asset/${assetId}`, payload);
};

export const editSessionById = (sessionId: string, payload: IAddSession) => {
	return httpClient.put<IOperationResultModel>(`/Asset/Session/${sessionId}`, {id: sessionId, ...payload});
};

export const editAssetLocation = (assetId: string, payload: ILocationMutate) => {
	return httpClient.put<IOperationResultModel>(`/Asset/Location/Update/${assetId}`, payload);
};

export const updateAssetEstimate = (assetEstimateId: string, payload: IAssetEstimateForm) => {
	return httpClient.put<IOperationResultModel>(`/Asset/Estimate/Update/${assetEstimateId}`, payload);
};

export const addEditVersionCast = (payload: IAssetAddEditVersionCast) => {
	return httpClient.put('/Asset/AddEditVersionCast', payload);
};

export const saveAdvertiser = (payload: any) => {
	return httpClient.post('/Asset/Advertiser/Save', payload);
};

export const getAllAdvertisers = (filterModel: any) => {
	return httpClient.post('/Asset/Advertiser/GetAll', filterModel);
};

export const deleteAsset = (assetId: string) => {
	return httpClient.delete(`/Asset/${assetId}`);
};

export const deleteSessionById = (sessionId: string) => {
	return httpClient.delete(`/Asset/Session/${sessionId}`);
};

export const saveAssetProduct = (payload: any) => {
	return httpClient.post(`/Asset/Product/Save/${payload.assetId}`, payload.assetProduct);
};

export const saveAssetUnion = (payload: any) => {
	return httpClient.post(`/Asset/Union/Save/${payload.assetId}`, payload.assetUnion);
};

export const retriveAssetProducts = (filterModel: any) => {
	return httpClient.post<IPagedResultModel<any>>('/Asset/Product/GetAll', filterModel);
};

export const getAllAssetUnions = (filterModel: any) => {
	return httpClient.post<IPagedResultModel<IUnionAgreement>>('/Asset/Union/GetAll', filterModel);
};

export const deleteAssetProduct = (params: any) => {
	return httpClient.delete('/Asset/Product/Delete', params);
};

export const deleteAssetAdvertiser = (params: any) => {
	return httpClient.delete('/Asset/Advertiser/Delete', params);
};

export const deleteAssetUnion = (params: any) => {
	return httpClient.delete('/Asset/Union/Delete', params);
};

export const deleteCast = (body: IDeleteCrew) => {
	return httpClient.deleteV2('/Asset/Cast/Delete', {headers: {}, body});
};

export const deleteCrew = (body: IDeleteCrew) => {
	return httpClient.deleteV2('/Asset/DeleteCrew', {headers: {}, body});
};

export const deleteParticipant = (body: IDeleteParticipant) => {
	return httpClient.deleteV2('/Asset/Participant/Delete', {headers: {}, body});
};

export const deleteAssetLocation = (assetId: string) => {
	return httpClient.delete<IOperationResultModel>(`/Asset/Location/Delete/${assetId}`);
};

export const deleteAssetEstimate = (assetEstimateId: string) => {
	return httpClient.delete<IOperationResultModel>(`/Asset/Estimate/Delete/${assetEstimateId}`);
};

export const deleteAssetSignatory = (payload: IDeleteAssetSignatory) => {
	return httpClient.delete<IOperationResultModel>(`/Asset/Signatory/Delete/${payload.signatoryCompanyId}/${payload.assetId}`);
};

export const deleteAssetTransferOfRight = (transferOfRightId: string) => {
	return httpClient.delete<IOperationResultModel>(`/Asset/TransferOfRight/Delete/${transferOfRightId}`);
};

export const saveAssetsElement = (payload: any) => {
	return httpClient.post('/Asset/Element/Save', payload);
};

export const deleteAssetElement = (payload: any) => {
	return httpClient.delete('/Asset/Element/Delete', payload);
};

export const updateAssetElement = (payload: any) => {
	return httpClient.put('/Asset/Element/Update', payload);
};

export const createAssetDocument = (payload: IDocumentValue) => {
	return httpClient.post('Asset/Document/Save', payload);
};

export const getAssetDocumentList = (filterModel: IFilterModel, id:any) => {
	return httpClient.post<IPagedResultModel<IDocumentViewModel>>(`Asset/Documents/${id}`, filterModel);
};

export const deleteAssetDocument = (payload: IDeleteDocumentValue) => {
	return httpClient.delete('Asset/Document/Delete', payload);
};

export const runExpireMpuEndDateRule = (payload: any) => {
	// return httpClient.post<any>('Asset/Rule/RunExpireMpuEndDate', payload);
	return httpClient.post<any>('Asset/Rule/CalculateMPUForAsset', payload);
};

export const runExpiryRule = (payload: any) => {
	return httpClient.post<any>('Asset/Rule/CalculateAssetExpiry', payload);
};

export const runTalentMpuRule = (payload: any) => {
	return httpClient.post<any>('Asset/Rule/CalculateTalentMPU', payload);
};

export const runTalentExpiry = (payload: any) => {
	return httpClient.post<any>('Asset/Rule/CalculateTalentExpiry', payload);
};

export const runExpireMpuEndDateLiftedRule = (payload: any) => {
	return httpClient.post<any>('Asset/Rule/RunExpireMpuEndDateForLifted', payload);
};

export const updateExpireMpuEndDate = (payload: any) => {
	return httpClient.post<any>('Asset/Rule/UpdateExpireMpuEndDate', payload);
};

export const calculateAssetExpiryAndUpdate = (assetId: string) => {
	return httpClient.post<any>(`Asset/Rule/CalculateAssetExpiryAndUpdate/${assetId}`);
};

export const updateExpiryDate = (payload: any) => {
	return httpClient.post<any>('Asset/Rule/UpdateExpiryDate', payload);
};

export const updateAssetMpuDate = (assetId: string, expireMpuEndDate: string) => {
	return httpClient.post<any>(`Asset/UpdateMpuDate/${assetId}`, expireMpuEndDate);
};

export const updateAssetExpiryDate = (assetId: string, expiryDate: string) => {
	return httpClient.post<any>(`Asset/UpdateExpiryDate/${assetId}`, expiryDate);
};

export const assetStatusManualUpdate = (assetId: string, isStatusManualUpdate: boolean) => {
	return httpClient.post<any>(`/Asset/StatusManualUpdate/${assetId}/${isStatusManualUpdate}`);
};

export const getLatestSessionRecordByUseRight = (assetId: string, useRight: string) => {
	return httpClient.post<ISessionRecord | undefined>(`Asset/${assetId}/Session/GetLatestByUseRight`, useRight);
};

export const updateAssetNote = (assetNoteMutateModel: IUpdateAssetNoteModel) => {
	return httpClient.post('Asset/Note/Mutate', assetNoteMutateModel);
};

export const updateAssetProjectNote = (assetNoteMutateModel: IUpdateAssetNoteModel) => {
	return httpClient.post('Asset/ProjectNote/Mutate', assetNoteMutateModel);
};

// #region Duplicated endpoint from another controller for permission handling.
export const getAdAgencyAdvertisersByIds = (ids: string[]) => {
	return httpClient.post<any>('/Asset/GetAdAgencyAdvertisersByIds', ids);
};

export const GetByWithFullNameSearchForBasicDetail = (filterModel: IFilterModel, searchText: string = '') => {
	return httpClient.post<IPagedResultModel<IPersonRecord>>('/Asset/GetByWithFullNameSearchForBasicDetail', { filterModel, searchText });
};

export const getAllPayrollVendorDetails = (payload: any) => {
	return httpClient.post<IPagedResultModel<ICompanyRecord>>('/Asset/PayrollVendor/GetAll', payload);
};

export const getGuaranteeContractByGuaranteeNumber = (guaranteeNumbers: string[]) => {
	return httpClient.post<IGuaranteeContractDetails[]>('/Asset/GuaranteeDetailByGuaranteeNumber', guaranteeNumbers);
};

export const getMasterRecordsForAsset = (assetId: string, filterModel: IFilterModel) => {
	return httpClient.post<IPagedResultModel<any>>(`/Asset/GuaranteePayment/Master/Record/GetAllForAsset/${assetId}`, filterModel);
};

export const getCompanyBasicDetails = (filterModel: IFilterModel, includeAllCompany: boolean = false) => {
	return httpClient.post<IPagedResultModel<ICompanyRecord>>('/Asset/GetCompanyBasicDetails', filterModel, { includeAllCompany: includeAllCompany || false });
};

export const getAllElementBasicDetails = (filterModel: IFilterModel) => {
	return httpClient.post<IPagedResultModel<IFilterModel>>('/Asset/GetElementBasicDetails', filterModel);
};

export const getAllLinkedAssets = (filterModel: IFilterModel) => {
	return httpClient.post<IPagedResultModel<IFilterModel>>('/Asset/GetLinkedAssets', filterModel);
};

export const getAgentInfo = (filterModel: IFilterModel, personId: string) => {
	return httpClient.post<IPagedResultModel<IAgencyInfo>>(`/Asset/GetAgentInfo/${personId}`, filterModel);
};

export const getAllAddressByCompany = (filterModel: IFilterModel, companyId: string) => {
	return httpClient.post<IPagedResultModel<ICompanyAddress>>(`/Asset/CompanyAddress/GetByCompany/${companyId}`, filterModel);
};

export const getAdAgencyAdvertiser = (filters: IFilterModel, id: string) => {
	return httpClient.post<IPagedResultModel<any>>(`/Asset/CompanyAdAgencyAdvertiser/Get/${id}`, filters);
};

export const getElementDeliverableSummaryFromAssetCtr = (elementId:any) => {
	return httpClient.get<any>(`/Asset/GetElementDeliverableSummary/${elementId}`);
};

export const getAllLinkedElementByAsset = (filterModel: IFilterModel, assetId: string) => {
	return httpClient.post<IPagedResultModel<any>>(`/Asset/Element/GetAllLinkedElementByAsset/${assetId}`, filterModel);
};

export const getTalentForAsset = (filterModel: IFilterModel, elementId:any) => {
	return httpClient.post<IPagedResultModel<any>>(`/Asset/Element/Talent/${elementId}`, filterModel);
};

export const getRightsAcquiredForAsset = (filterModel: IFilterModel, id:any) => {
	return httpClient.post<IPagedResultModel<any>>(`/Asset/Element/GetRightsAcquired/${id}`, filterModel);
};

export const unLinkLinkedAssetFromAsset = (payload: any) => {
	return httpClient.post('/Asset/Element/UnlinkLinkedAsset', payload);
};

export const addLinkedAssetForAsset = (payload: any) => {
	return httpClient.post('/Asset/Element/AddLinkedAsset', payload);
};

export const updateLinkedAssetForAsset = (payload: any) => {
	return httpClient.post('/Asset/Element/UpdateLinkedAsset', payload);
};

export const getStaffListByCompanyForAsset = (filterModel: IFilterModel,id:any) => {
	return httpClient.post<IPagedResultModel<StaffViewModel>>(`/Asset/Staff/GetAll/${id}`, filterModel);
};

export const getAllSignatories = (filterModel: IFilterModel, companyId: string) => {
	return httpClient.post<IPagedResultModel<ISignatoryRecord>>(`/Asset/Company/Signatories/${companyId}`, filterModel);
};

export const savePersonAsStaffFromAsset = (payload: any) => {
	return httpClient.post('/Asset/People/AddStaff', payload);
};

export const getContractDetailsByAssetId = (filters: FilterModel, assetId: string) => {
	return httpClient.post<IPagedResultModel<IContractRecord>>(`/Asset/Contract/GetDetailsByAssetId/${assetId}`, filters);
};

export const getContractBasicDetails = (filters: FilterModel) => {
	return httpClient.post<IPagedResultModel<IContractRecord>>('/Asset/Contract/GetByForBasicDetails', filters);
};

export const getPersonAgentDetailsForAsset = (personId: any) => {
	return httpClient.get<any>(`/Asset/GetPersonAgentDetailsForAsset/${personId}`);
};
// #endregion
