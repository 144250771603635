import { getUserPermission } from '@/services/auth';
import { IUserRecord } from '@/services/models/user';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { localStorageKeys, ModuleRouteMapping } from '../constants/constants';
import useNotification from './useNotification';
import store from '@/store';
import { resetEditFormValidation } from '@/store/app/reducer';

type TByPassPermissionCheck = 'IGNORE_BY_PASS' | 'IGNORE_DEFAULT_ROUTE_ONLY' | 'DEFAULT_CHECK';
/**
 * usePermission is a hook that returns the user's permission information and a function to check
 * if the user has permission to access a specific route or item.
 *
 * The hook also checks if the user has permission to access the current route and if not,
 * it redirects the user to the default route and shows a notification.
 *
 * @returns an object containing the user's permission information and a function to check
 * if the user has permission to access a specific route or item.
 */
const usePermission = () => {
	
	// #region State variable & hooks.
	const [userPermissionDetail, setUserPermissionDetail] = useState<IUserRecord>();
	const notification = useNotification();
	const location = useLocation();
	const navigation = useNavigate();
	const params = useParams();
	// #endregion
	
	// #region constants and other variables
	const DEFAULT_NAV_ROUTE = '/dashboard';
	const REFRESH_TIME = 1000 * 60 * 5; // 5 minutes
	const currentRoute = location.pathname;
	const ACCESSING_MODULE = Object.values(ModuleRouteMapping)
		.find((m) => m.moduleRoutes.some(ar => currentRoute?.startsWith(ar)))?.name?.trim() || 
		currentRoute?.split('/')[1]?.trim();
	// #endregion


	const { isLoading: isPermissionLoading, data, refetch: refetchPermission } = useQuery({
		queryKey: ['user', 'permissions', userPermissionDetail?.id],
		queryFn: getUserPermission,
		refetchOnReconnect: true,
		refetchOnWindowFocus: false,
		refetchInterval: REFRESH_TIME,
		refetchIntervalInBackground: true,
		keepPreviousData: true,
		retry: 3,
		retryDelay: 1000,
		onSuccess: (res) => {
			setUserPermissionDetail(res?.data);
		},
		onError: (error) => {
			console.error(error);
		},
	});

	/**
	 * Checks if the user is an admin user.
	 * 
	 * @returns True if the user is an admin user.
	 */
	const isAdminUser = (): boolean => {
		// TODO: Needs to update the logic.
		return (userPermissionDetail || data?.data)?.userRole?.roleTypes[0]?.name?.trim()?.toLowerCase() === 'admin';
	};


	/**
	 * Checks if the user is a 7Miles client.
	 * 
	 * @returns {boolean} True if the user is a 7Miles client.
	 */
	const is7MilesClient = (): boolean => {
		return isAdminUser() || (userPermissionDetail || data?.data)?.is7MilesClient || false;
	};

	/**
	 * Checks if the user is allowed to bypass permission checks.
	 * 
	 * @param {TByPassPermissionCheck} byPassPermissionCheck - The type of permission check to bypass.
	 * @returns {boolean} True if the user is allowed to bypass permission checks.
	 * 
	 * The default value of `byPassPermissionCheck` is `DEFAULT_CHECK`, which checks if the user is an
	 * admin user or if the current route is the default route.
	 * 
	 * If `byPassPermissionCheck` is set to `IGNORE_DEFAULT_ROUTE_ONLY`, the function will only return
	 * true if the user is an admin user.
	 * 
	 * If `byPassPermissionCheck` is set to `IGNORE_BY_PASS`, the function will always return false.
	 */
	const canByPassPermission = (byPassPermissionCheck: TByPassPermissionCheck = 'DEFAULT_CHECK') => {
		// TODO: Needs to update once admin script is created like union init script.
		let canByPass: boolean = false;

		switch(byPassPermissionCheck){
		case 'DEFAULT_CHECK' : canByPass = isAdminUser() || currentRoute?.startsWith(DEFAULT_NAV_ROUTE); break;
		case 'IGNORE_DEFAULT_ROUTE_ONLY' : canByPass = isAdminUser(); break;
		case 'IGNORE_BY_PASS': canByPass = false; break; 
		}

		return canByPass;
	};

	/**
	 * Checks if the user has permission to access a specific item.
	 * 
	 * @param item The name of the item to check.
	 * @param permission The type of permission to check for. Can be 'READ', 'WRITE', or 'DELETE'.
	 * @returns True if the user has the specified permission to access the item.
	 */
	const hasPermissionToItem = (item: string, permission: 'READ' | 'WRITE' | 'DELETE', byPassPermissionCheck: TByPassPermissionCheck = 'IGNORE_DEFAULT_ROUTE_ONLY') => {

		// TODO: Will be removed once admin script is created like union init script.
		// IGNORE_DEFAULT_ROUTE_ONLY
		if(canByPassPermission(byPassPermissionCheck)) return true;

		const accessModuleItemPermission = (userPermissionDetail || data?.data)?.userRole?.roleTypes?.at(0)
			?.moduleAccesses?.flatMap((m) => m?.itemAccesses)
			?.find((m) => m?.name?.toLowerCase() === item?.trim()?.toLowerCase());
			
		const objMapping = {
			READ: 'canRead',
			WRITE: 'canWrite',
			DELETE: 'canDelete',
		};
			
		const hasPermission = accessModuleItemPermission?.accessPermission && accessModuleItemPermission?.accessPermission[objMapping[permission]];
		//return !!hasPermission;

		return hasPermission;
	};

	/**
	 * Checks if the user has permission to access a specific module.
	 * 
	 * @param module The name of the module to check. (Use constant 'ModuleNames')
	 * @param permission The type of permission to check for. Can be 'READ', 'WRITE', or 'DELETE'.
	 * @returns True if the user has the specified permission to access the module.
	 */
	const hasPermissionToModule = (module: string, permission: 'READ' | 'WRITE' | 'DELETE', byPassPermissionCheck: TByPassPermissionCheck = 'DEFAULT_CHECK') => {

		// TODO: Will be removed once admin script is created like union init script.
		// DEFAULT_CHECK
		if(canByPassPermission(byPassPermissionCheck)) return true;

		const accessModulePermission = (userPermissionDetail || data?.data)?.userRole?.roleTypes?.at(0)
			?.moduleAccesses?.find((m) => m?.name?.toLowerCase() === module?.trim()?.toLowerCase());	
	
		const objMapping = {
			READ: 'canRead',
			WRITE: 'canWrite',
			DELETE: 'canDelete',
		};
	
		return !!(
			accessModulePermission?.accessPermission && accessModulePermission?.accessPermission[objMapping[permission]]
		);

	};

	/**
	 * Checks if the user has permission to access the current route and if not, it redirects the user
	 * to the default route and shows a notification.
	 */
	const handleModulePermissionCheck = () => {
		if ((data?.data?.id || userPermissionDetail?.id) && !isPermissionLoading) {
			const accessModulePermission = (userPermissionDetail || data?.data)?.userRole?.roleTypes?.at(0)?.moduleAccesses?.find((m) => m.name?.toLowerCase() === ACCESSING_MODULE?.toLowerCase());

			// TODO: Will be removed once admin script is created like union init script.
			if(canByPassPermission()) return true;

			if (!accessModulePermission?.accessPermission?.canRead) {
				// TODO: When the user is in the profile page. For the first time the data is not loaded properly from the API. 
				// We can not wait till the API is loaded so redirect to the previous route is checking load state.
				if(!isPermissionLoading){
					navigation(DEFAULT_NAV_ROUTE, { replace: true });
				}
				notification.notify({
					message: 'You do not have permission to access this route.',
					type: 'warning'
				});
				refetchPermission();
			}
		}
	};


	/**
	 * Notifies the user of a permission denied error, and optionally handles
	 * client-side navigation to the previous route.
	 * 
	 * @param {boolean} [handleNavigation] - If true, the function will navigate to the previous route.
	 * @param {string} [redirectUrl] - If provided, the function will navigate to this url.
	 */
	const notifyPermissionDenied = (handleNavigation: boolean = false, redirectUrl: string | undefined = undefined) => {
		notification.notify({
			message: 'Permission denied.',
			type: 'warning'
		});
		refetchPermission();
		if(handleNavigation){
			store.dispatch(resetEditFormValidation());
			
			if(redirectUrl){
				navigation(redirectUrl);
			}
			else{
				navigation(-1);
			}
		}
	};

	const handleAccessingAPIHeaders = () => {
		const storedModuleName = localStorage.getItem(localStorageKeys.MODULE_NAME);

		if(storedModuleName?.trim() !== ACCESSING_MODULE) {
			localStorage.setItem(localStorageKeys.MODULE_NAME, ACCESSING_MODULE);
		}
		
		const accessingEntityIds: string[] = Object.values(params)?.flat().filter(param => !!param && typeof param === 'string') as string[] || [];
		localStorage.setItem(localStorageKeys.ACCESSING_ENTITY_IDS, JSON.stringify(accessingEntityIds));
	};
	// #endregion

	useEffect(() => {
		handleModulePermissionCheck();
	}, [location.pathname, userPermissionDetail, navigation, isPermissionLoading]);

	useEffect(() => {
		handleAccessingAPIHeaders();
	}, [ACCESSING_MODULE, params]);

	return {
		ACCESSING_MODULE,
		userPermissionDetail, 
		hasPermissionToItem, 
		hasPermissionToModule, 
		notifyPermissionDenied,
		isPermissionLoading,
		isAdminUser,
		is7MilesClient,
	};
};

export default usePermission;
